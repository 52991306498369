.schedule-field {}

.schedule-field__block {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ccc;
  align-items: center;
  padding: 10px 0;
}
.schedule-field__block:last-of-type {
  border: none;
}

.schedule-field__day {
  flex: 1;
}

.schedule-field__label {
  padding: 0 5px;
}

/* .schedule-field__time-input {} */

.schedule-field__time-input input {
  font-size: 1em;
  padding: 0;
  background-color: inherit;
  color: inherit;
  /* box-sizing: border-box; */
  box-shadow: none;
  background-image:none;
  background-clip: padding-box;
  outline: none;
}

.schedule-type-menu {
  display: flex;
  align-items: center;
}

.schedule-type-menu__item {
  display: flex;
  margin-left: 10px;
  padding: 3px 7px;
  border: 1px solid #575757;
  color: #575757;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.15s;
}
.schedule-type-menu__item:first-of-type {
  margin-left: 0;
}
.schedule-type-menu__item_is-active_yes {
  background-color: #575757;
  color: white;
  cursor: unset;
}