.text-bot {}

.article-tab {
  display: flex;
}

.article-tab__sidebar {
  width: 32%;
  max-width: 32%;
  padding-right: .25em;
}
.article-tab__content {
  width: 68%;
  padding-left: .25em;
}

.article-tab {}

.article-tab__tree {
  min-height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  padding-right: 1em;
  width: 100%;
  height: 1px;
}

.article-tab__editor {
  padding-right: 1em;
  overflow-y: scroll;
  position: relative; /* somehow fixes a bug with extra space under in spite of js-set height */
  height: 1px;
}

.behavior-tab {
  display: flex;
}

.behavior-tab__sidebar {
  width: 50%;
  padding-right: .25em;
}

.behavior-tab__content {
  width: 50%;
  padding-left: .25em;
}

.behavior-tab__list {
  padding-right: 1em;
  height: 1px;
  overflow-y: auto;
}
