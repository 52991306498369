.button-class-list-admin {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem 0;
}

.button-class-list-admin-head {
  display: flex;
  flex-direction: row;
}
.button-class-list-admin-head__title {
  font-size: 28px;
  font-weight: normal;
  color: #797979;
}
.button-class-list-admin-head__button-list {
  margin-left: auto;
  margin-top: 0.3rem;
}

.button-class-list-admin-head-button-list {
  padding-left: 0;
  list-style: none;
}
.button-class-list-admin-head-button-list__item {
  display: inline;
  margin-left: 0.5rem;
}

.button-class-list-admin__tab {
  display: flex;
  padding: 2.5rem 0;
}

.button-class-list-admin__button-class-table {
  width: 50%;
  flex-grow: 1;
  margin-right: 1.5rem;
}

.button-class-list-admin__button-class-form {
  border: 1px solid #b5d2e1;
  padding: 2rem;
  width: 50%;
}