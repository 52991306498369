.action-bot-content {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  /* min-height: 100%; */
}

/* .acton-bot-content__space {} */
.acton-bot-content__space.acton-bot-content__space_for_action-editor {
  padding-right: 1em;
  overflow-y: scroll;
  position: relative; /* somehow fixes a bug with extra space under in spite of js-set height */
  height: 1px;
}

/* !!! */
.action-bot-content .ant-form-item:last-of-type {
  margin-bottom: 0;
}