body {
  overflow-y: unset;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: unset;
  max-width: unset;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* .app {
  width: 1100px;
  min-width: 960px;
  max-width: 1200px;
  margin: 0 auto;
} */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}