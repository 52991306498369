.rs-picker-has-value .rs-btn .rs-picker-toggle-value, 
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: inherit;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, 
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, 
.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active, 
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  border-color: inherit;
}