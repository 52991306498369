.action-bot-sidebar {
  /* flex: 1; */
  /* min-height: 100%; */
}

.action-bot-sidebar__space {}
.action-bot-sidebar__space.action-bot-sidebar__space_for_action-tree {
  flex: 1;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  padding-right: 1em;
  width: 100%;
  height: 1px;
}