.api-request-list-admin {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem 0;
  }
  
  .api-request-list-admin-head {
    display: flex;
    flex-direction: row;
  }
  .api-request-list-admin-head__title {
    font-size: 28px;
    font-weight: normal;
    color: #797979;
  }
  .api-request-list-admin-head__button-list {
    margin-left: auto;
    margin-top: 0.3rem;
  }
  
  .api-request-list-admin-head-button-list {
    padding-left: 0;
    list-style: none;
  }
  .api-request-list-admin-head-button-list__item {
    display: inline;
    margin-left: 0.5rem;
  }
  
  .api-request-list-admin__tab {
    display: flex;
    padding: 2.5rem 0;
  }
  
  .api-request-list-admin__bot-table {
    width: 50%;
    flex-grow: 1;
    margin-right: 1.5rem;
  }
  
  .api-request-list-admin__bot-form {
    border: 1px solid #b5d2e1;
    padding: 2rem;
    width: 50%;
  }