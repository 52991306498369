.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  text-align: center;
  padding-top: 15%;
  background-color: #243142;
  color: white;
}