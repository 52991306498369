.nt-form__block {
  margin-bottom: 1rem;
}
.nt-form__field-block {
  margin-bottom: 0.5rem;
}

/* .nt-form__title {} */

.nt-form__label {
  padding: 0.5rem 0;
}
.nt-form__label-tooltip {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAFWElEQVR4Xu2bT1LjRhTGvyeTmtnN3CDMCZBOMOYEgV0qsQvYBHk1cIIhJ8BZ2ZPNmLKTym7MCcacQPIJ4twAdpkC6aVatkFqtayW1MJOYVWxstTd7/def+/1Hwgv/KEXbj+2ALYRUCOBH3vebsOy3lsMm8E2AbuY/8WfGQMzAvkhwQ/C8OavjjOrcViJpo1PgZ9+9+xGaB0BfKAwVteuGUDjwAqv/vzF8XU/KvOeMQCtntcE0UcCmmUGkvUNAxMw/zrqOBOT7S7bqgxAeNwK6dK04bKxAkRo8bnpiKgEoN2fXgJ8lu09viNgwgwRxpOwgVvZgAhggLcAmkSwAfphtaepO3T3zk1FQykAQtx2iL4QxIBVD18zYTA6dcZlBtrueccgHGTBYMB/YD40IZaFAcxFjr4Ckdekh6/vGWcmBiYaFqC/I3QzQNwGFu9XnRKFAAihI6IvsvHM/A+A47qEKhJYYEBE30vEK0PQBpDleWa+2nmNs8GJc1sm3HW/Of7svX34F10iOjIJQQvAPBTJS4U988mw4wx0jTDxXvuTdwamSxnCPbNTZuppAWj1fS8leGswfmn0XCTpcxyCEMaRaztFIecCaPW9LoE+JBpeo/GPEBSRwODfRq6TmZZVcFYCWMx7EfqPj5jzo45zXJT048D7Pse/HbpiiVDuafU8IYwJTQgsdopkhpWdt/r+13iFJ9R+5zXsKoLXNghgIYx+PDuIinHk2vu6SDMBLFKeyPdx7+9XTXUmAYiBVR1nNoC09yuFfh1T4KlNbxwvlopEgRKAau7fM78rk2Z0Q7HKe4s0/Xe8DV0tUAJIKz9fD11HrO839mn35SjQywhKAO2+L2g+7tww8WHZhc1zEVPUBrOha7/L6z8FQA4nBt+NXEex8Mlr+vl/lwVWZ9qmAKRJmg1/01kgjrnd8yYgeh9LW7mlegqAPP95vh11YcqfdQJo9bwLIvq4HKtOZaiKgARFZq6c+xNeMlgIyU5J1QTMN8OOs3KPMg1AEkDddKIbIXVGgCJ95wqhCoCxWl0FpU4Aor+i7W8ByF4qSlA39OsshatozDYCFEo6iy8v/88iKJbvo44jn0UmTN6mwVQESFtgL7EQkjYcX1gprFpbV9m3e84sY2QxtNhmSgghDO4C15Vm5UWcjgAKW599Q6Q2ACY3ROrcEqsDgPEtsaimltbWDIxHrn1YtPJ7Dg1o9X1xVP+0ZaexClyOa7stvsqjqR0WYNZ4xc4mHYwE36JD26dqr4D3M0VwCUWlBeKcfujaJ2WngkkNaPd9cUCaOKYrWrrnnsspD0eJz4enTrcsBBPfqY7JdbbA5L5zAUR1Qd/zCbSX+NhgbVAUiPp4nKcj18m4s5TdgxaA+aUoCAhv1g2h1Z9+IHAi+sTW/QPDLnNypQVAGD2/D4hJCgIwaLzi8yrCqBMB4iQ4+BbdDEnMeWF8aKFZ5Eg83p82gBwIM2Y+qXpynAVisdsrBC+xtq9qfG4WUA1oRSRAFEsPzOdlQlHV1+I+oriFmjqXNGF8KQDio4UmjFPCuLBCgGCi8R+ne1c64S2/8/On6RExH6gMF+8yePrAODAButAUkAeqTJHSSwIGiMQtjkmA4E51VbaBxhtmboK5mXfnuEyqW+WESgCWutAI0E2cyZVxe943zDdBA2dlxS6r+coAlg1HQgVcGAfBfMPARV0CawxAvHy2QhyDcaC42prn5+j36OotYRxaGJj2eKlKUGvUipeidTrQZIJN0b/NYFeGIowlYMai0GL498DEhLjpjtl4BOh2vCnvbQFsiifWNY5tBKyL/Kb0u42ATfHEusbx4iPgP38sW25SZ/DRAAAAAElFTkSuQmCC) no-repeat;
  background-size: contain;
  position: relative;
  top: 4px;
  margin: 0 0.2em;
}

.nt-form-error-list {
  color: red;
  margin: 0;
  padding: 0;
  list-style: none;
}

.nt-form__button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}