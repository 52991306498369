.paginator {
  display: inline-block;
}
.paginator__button {
  color: #fe5622;
  border: 1px solid;
  background-color: inherit;
  border-radius: 3px;
  height: 3.5rem;
  width: 3rem;
}
.paginator__button:nth-of-type(2) {
  margin-left: 0.3rem;
}
.paginator__button:disabled {
  color: #e5e5ea;
}