.action-bot {
  width: 100%;
  display: flex;
  /* flex: 1; */
  /* min-height: 100%; */
  padding: 2em 0;
}

.action-bot__sidebar,
.action-bot__content {
  /* flex: 1; */
  /* min-height: 100%; */
  /* padding: 2em 0; */
}
.action-bot__sidebar {
  width: 36%;
  max-width: 36%;
  padding-right: .25em;
}
.action-bot__content {
  width: 64%;
  max-width: 64%;
  padding-left: .25em;
}